.owl-theme .owl-controls {
	margin-top: 10px;
	text-align: center
}

.owl-theme .owl-controls .owl-buttons div {
	color: #fff;
	display: inline-block;
	zoom: 1;
	*display: inline;
	font-size: 12px;
	filter: Alpha(Opacity=50);
	opacity: 1
}

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
	filter: Alpha(Opacity=100);
	opacity: 1;
	text-decoration: none
}

.owl-theme .owl-controls .owl-page {
	display: inline-block;
	zoom: 1;
	*display: inline
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);
	opacity: .5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #869791
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	filter: Alpha(Opacity=100)
}

.owl-theme .owl-controls .owl-page span.owl-numbers {
	height: auto;
	width: auto;
	color: #fff;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px
}

.owl-item.loading {
	min-height: 150px;
}