.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-target {
	position: relative;
	direction: ltr
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0
}

.noUi-handle {
	position: relative;
	z-index: 1
}

.noUi-stacking .noUi-handle {
	z-index: 10
}

.noUi-state-tap .noUi-origin {
	-webkit-transition: left .3s, top .3s;
	transition: left .3s, top .3s
}

.noUi-state-drag * {
	cursor: inherit!important
}

.noUi-base {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
	height: 8px
}

.noUi-horizontal .noUi-handle {
	width: 15px;
	height: 15px;
	left: -10px;
	top: -4px
}

.noUi-vertical {
	width: 18px
}

.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px
}

.noUi-background {
	background: #fafafa;
	box-shadow: inset 0 1px 1px #f0f0f0
}

.noUi-connect {
	background: #7e7e7e;
	box-shadow: inset 0 0 3px rgba(51, 51, 51, .45);
	-webkit-transition: background 450ms;
	transition: background 450ms
}

.noUi-origin {
	border-radius: 2px
}

.noUi-target {
	border-radius: 4px;
	border: 1px solid #d3d3d3;
	box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb
}

.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51, 51, 51, .45), 0 3px 6px -5px #bbb
}

.noUi-dragable {
	cursor: w-resize
}

.noUi-vertical .noUi-dragable {
	cursor: n-resize
}

.noUi-handle {
	border-radius: 50%;
	background: #7e7e7e;
	cursor: pointer
}

.noUi-active {
	box-shadow: inset 0 0 1px #7e7e7e, inset 0 1px 7px #5c5c5c, 0 3px 6px -3px #3a3a3a
}

[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #b8b8b8
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed
}

.noUi-pips,
.noUi-pips * {
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-pips {
	position: absolute;
	font: 400 12px Arial;
	color: #a3a3a3
}

.noUi-value {
	width: 40px;
	position: absolute;
	text-align: center
}

.noUi-value-sub {
	color: #ccc;
	font-size: 10px
}

.noUi-marker {
	position: absolute;
	background: #ccc
}

.noUi-marker-sub {
	background: #aaa
}

.noUi-marker-large {
	background: #aaa
}

.noUi-pips-horizontal {
	padding-bottom: 37px;
	bottom: 10%;
	left: 0;
	width: 100%
}

.noUi-value-horizontal {
	margin-left: -20px;
	padding-top: 5px
}

.noUi-value-horizontal.noUi-value-sub {
	padding-top: 15px
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px;
	display: none
}

.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
	height: 15px
}

.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%
}

.noUi-value-vertical {
	width: 15px;
	margin-left: 20px;
	margin-top: -5px
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
	width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
	width: 15px
}