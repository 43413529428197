/**
 * bicon v1.0.0 - 
 * @link 
 * @copyright 2016-2017 ThemeBucket
 * @license MIT
 */

@charset 'UTF-8';
@font-face {
	font-family: 'bicon';
	font-weight: 400;
	font-style: normal;
    src: url(../css/fonts/bicon.eot?b8vo7z);
	src: url(../css/fonts/bicon.eot?b8vo7z#iefix) format('embedded-opentype'), url(../css/fonts/bicon.ttf?b8vo7z) format('truetype'), url(../css/fonts/bicon.woff?b8vo7z) format('woff')
}

.bi {
	font-family: 'bicon'!important;
	font-weight: 400;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.bi-accordion-horizontal:before {
	content: ''
}

.bi-accordion-vertical:before {
	content: ''
}

.bi-alarm-clock:before {
	content: ''
}

.bi-alien:before {
	content: ''
}

.bi-alien-gun:before {
	content: ''
}

.bi-anchor:before {
	content: ''
}

.bi-android:before {
	content: ''
}

.bi-apple:before {
	content: ''
}

.bi-arrow-left-rounded:before {
	content: ''
}

.bi-arrow-left-square:before {
	content: ''
}

.bi-arrow-right-rounded:before {
	content: ''
}

.bi-arrow-right-square:before {
	content: ''
}

.bi-article:before {
	content: ''
}

.bi-badge1:before {
	content: ''
}

.bi-badge2:before {
	content: ''
}

.bi-badge3:before {
	content: ''
}

.bi-bamboo:before {
	content: ''
}

.bi-battery:before {
	content: ''
}

.bi-beach-seat:before {
	content: ''
}

.bi-bicycle:before {
	content: ''
}

.bi-blog:before {
	content: ''
}

.bi-blue-tooth:before {
	content: ''
}

.bi-board:before {
	content: ''
}

.bi-body:before {
	content: ''
}

.bi-bond:before {
	content: ''
}

.bi-book:before {
	content: ''
}

.bi-bowl:before {
	content: ''
}

.bi-brick-wall:before {
	content: ''
}

.bi-brush:before {
	content: ''
}

.bi-brush-paint:before {
	content: ''
}

.bi-brush-roll:before {
	content: ''
}

.bi-bug:before {
	content: ''
}

.bi-bulb:before {
	content: ''
}

.bi-calculation:before {
	content: ''
}

.bi-calendar:before {
	content: ''
}

.bi-camera:before {
	content: ''
}

.bi-candle:before {
	content: ''
}

.bi-candles:before {
	content: ''
}

.bi-car:before {
	content: ''
}

.bi-carousal:before {
	content: ''
}

.bi-caution:before {
	content: ''
}

.bi-chair1:before {
	content: ''
}

.bi-chair2:before {
	content: ''
}

.bi-chat-bubble:before {
	content: ''
}

.bi-chat-bubble-single:before {
	content: ''
}

.bi-cheese:before {
	content: ''
}

.bi-clip:before {
	content: ''
}

.bi-clip-board:before {
	content: ''
}

.bi-cloud:before {
	content: ''
}

.bi-cloud-down:before {
	content: ''
}

.bi-cloud-up:before {
	content: ''
}

.bi-code:before {
	content: ''
}

.bi-code-browser:before {
	content: ''
}

.bi-code-clean:before {
	content: ''
}

.bi-color-plate:before {
	content: ''
}

.bi-compass:before {
	content: ''
}

.bi-compass-math:before {
	content: ''
}

.bi-crop:before {
	content: ''
}

.bi-cross:before {
	content: ''
}

.bi-cross-game:before {
	content: ''
}

.bi-cross-gap:before {
	content: ''
}

.bi-crown:before {
	content: ''
}

.bi-cube:before {
	content: ''
}

.bi-cup:before {
	content: ''
}

.bi-cup-coffee:before {
	content: ''
}

.bi-delivery-van:before {
	content: ''
}

.bi-direction-both:before {
	content: ''
}

.bi-direction-right:before {
	content: ''
}

.bi-disc:before {
	content: ''
}

.bi-dislike:before {
	content: ''
}

.bi-door-path:before {
	content: ''
}

.bi-drag:before {
	content: ''
}

.bi-drag-inside:before {
	content: ''
}

.bi-drag-outside:before {
	content: ''
}

.bi-drawer:before {
	content: ''
}

.bi-dribbble:before {
	content: ''
}

.bi-dropper:before {
	content: ''
}

.bi-ellipsis-horizontal:before {
	content: ''
}

.bi-ellipsis-vertical:before {
	content: ''
}

.bi-emo-normal:before {
	content: ''
}

.bi-emo-sad:before {
	content: ''
}

.bi-emo-smile:before {
	content: ''
}

.bi-envelop:before {
	content: ''
}

.bi-facebook:before {
	content: ''
}

.bi-female:before {
	content: ''
}

.bi-file:before {
	content: ''
}

.bi-file-cabinet:before {
	content: ''
}

.bi-files:before {
	content: ''
}

.bi-film:before {
	content: ''
}

.bi-film-roll:before {
	content: ''
}

.bi-finger-index:before {
	content: ''
}

.bi-finger-print:before {
	content: ''
}

.bi-flag:before {
	content: ''
}

.bi-folder:before {
	content: ''
}

.bi-footer:before {
	content: ''
}

.bi-form:before {
	content: ''
}

.bi-forward:before {
	content: ''
}

.bi-gender:before {
	content: ''
}

.bi-gender-female:before {
	content: ''
}

.bi-gender-male:before {
	content: ''
}

.bi-gender-sign:before {
	content: ''
}

.bi-ghost:before {
	content: ''
}

.bi-gift-box:before {
	content: ''
}

.bi-globe1:before {
	content: ''
}

.bi-globe2:before {
	content: ''
}

.bi-globe3:before {
	content: ''
}

.bi-globe4:before {
	content: ''
}

.bi-google:before {
	content: ''
}

.bi-graduation-cap:before {
	content: ''
}

.bi-graph-bar:before {
	content: ''
}

.bi-graph-pie:before {
	content: ''
}

.bi-grid:before {
	content: ''
}

.bi-grid-even:before {
	content: ''
}

.bi-grid-masonry:before {
	content: ''
}

.bi-grid-twist:before {
	content: ''
}

.bi-group:before {
	content: ''
}

.bi-hand:before {
	content: ''
}

.bi-hand-mike:before {
	content: ''
}

.bi-hand-watch:before {
	content: ''
}

.bi-header:before {
	content: ''
}

.bi-headphone:before {
	content: ''
}

.bi-headset:before {
	content: ''
}

.bi-heart-beat:before {
	content: ''
}

.bi-highlighter:before {
	content: ''
}

.bi-home:before {
	content: ''
}

.bi-hotdog:before {
	content: ''
}

.bi-ice-cream:before {
	content: ''
}

.bi-image:before {
	content: ''
}

.bi-jar:before {
	content: ''
}

.bi-laptop:before {
	content: ''
}

.bi-layer:before {
	content: ''
}

.bi-lens:before {
	content: ''
}

.bi-like:before {
	content: ''
}

.bi-link:before {
	content: ''
}

.bi-linkedin:before {
	content: ''
}

.bi-linux:before {
	content: ''
}

.bi-list:before {
	content: ''
}

.bi-location-pointer:before {
	content: ''
}

.bi-lock:before {
	content: ''
}

.bi-love:before {
	content: ''
}

.bi-madel:before {
	content: ''
}

.bi-magnet:before {
	content: ''
}

.bi-male:before {
	content: ''
}

.bi-map-pointer:before {
	content: ''
}

.bi-measurement:before {
	content: ''
}

.bi-microphone1:before {
	content: ''
}

.bi-microphone2:before {
	content: ''
}

.bi-mobile:before {
	content: ''
}

.bi-money:before {
	content: ''
}

.bi-money-bag:before {
	content: ''
}

.bi-monitor:before {
	content: ''
}

.bi-mouse:before {
	content: ''
}

.bi-muscle:before {
	content: ''
}

.bi-nuclear-circle:before {
	content: ''
}

.bi-office-bag:before {
	content: ''
}

.bi-pacman:before {
	content: ''
}

.bi-paper:before {
	content: ''
}

.bi-paper-fold:before {
	content: ''
}

.bi-paper-plane:before {
	content: ''
}

.bi-pen:before {
	content: ''
}

.bi-pencil:before {
	content: ''
}

.bi-pendrive:before {
	content: ''
}

.bi-phone:before {
	content: ''
}

.bi-pillar:before {
	content: ''
}

.bi-pin:before {
	content: ''
}

.bi-pin-man:before {
	content: ''
}

.bi-pin-man-range:before {
	content: ''
}

.bi-plane:before {
	content: ''
}

.bi-play-button:before {
	content: ''
}

.bi-playing-card:before {
	content: ''
}

.bi-play-store:before {
	content: ''
}

.bi-plus:before {
	content: ''
}

.bi-plus-gap:before {
	content: ''
}

.bi-power:before {
	content: ''
}

.bi-printer:before {
	content: ''
}

.bi-quote:before {
	content: ''
}

.bi-radar:before {
	content: ''
}

.bi-radiation:before {
	content: ''
}

.bi-recycle:before {
	content: ''
}

.bi-refresh-time:before {
	content: ''
}

.bi-reply:before {
	content: ''
}

.bi-responsive-device:before {
	content: ''
}

.bi-rocket1:before {
	content: ''
}

.bi-rocket2:before {
	content: ''
}

.bi-rss:before {
	content: ''
}

.bi-safety-cap:before {
	content: ''
}

.bi-safety-kit:before {
	content: ''
}

.bi-sand-watch:before {
	content: ''
}

.bi-scale:before {
	content: ''
}

.bi-scanner:before {
	content: ''
}

.bi-scissor:before {
	content: ''
}

.bi-screen:before {
	content: ''
}

.bi-search:before {
	content: ''
}

.bi-server:before {
	content: ''
}

.bi-share:before {
	content: ''
}

.bi-shield:before {
	content: ''
}

.bi-shopping-bag1:before {
	content: ''
}

.bi-shopping-bag2:before {
	content: ''
}

.bi-shopping-bag3:before {
	content: ''
}

.bi-shopping-bag4:before {
	content: ''
}

.bi-shopping-cart-emply:before {
	content: ''
}

.bi-shopping-cart-full:before {
	content: ''
}

.bi-shutter1:before {
	content: ''
}

.bi-sign-in:before {
	content: ''
}

.bi-sign-out:before {
	content: ''
}

.bi-sitemap1:before {
	content: ''
}

.bi-sitemap2:before {
	content: ''
}

.bi-slider:before {
	content: ''
}

.bi-slider-doc:before {
	content: ''
}

.bi-slider-filter:before {
	content: ''
}

.bi-slider-image:before {
	content: ''
}

.bi-slider-range:before {
	content: ''
}

.bi-slider-video:before {
	content: ''
}

.bi-smart-watch:before {
	content: ''
}

.bi-spa-face:before {
	content: ''
}

.bi-spark:before {
	content: ''
}

.bi-spa-stone:before {
	content: ''
}

.bi-spa-stone-flower:before {
	content: ''
}

.bi-speaker-off:before {
	content: ''
}

.bi-speaker-on:before {
	content: ''
}

.bi-steps:before {
	content: ''
}

.bi-stop-watch:before {
	content: ''
}

.bi-support:before {
	content: ''
}

.bi-tab:before {
	content: ''
}

.bi-table-lamp:before {
	content: ''
}

.bi-tablet:before {
	content: ''
}

.bi-tag:before {
	content: ''
}

.bi-target:before {
	content: ''
}

.bi-target-arrow:before {
	content: ''
}

.bi-terminal:before {
	content: ''
}

.bi-tick:before {
	content: ''
}

.bi-tie:before {
	content: ''
}

.bi-tie-knot:before {
	content: ''
}

.bi-tools:before {
	content: ''
}

.bi-tree:before {
	content: ''
}

.bi-twitter:before {
	content: ''
}

.bi-twitter-bird:before {
	content: ''
}

.bi-ufo:before {
	content: ''
}

.bi-umbralla:before {
	content: ''
}

.bi-unlock:before {
	content: ''
}

.bi-up-down:before {
	content: ''
}

.bi-user-ID:before {
	content: ''
}

.bi-video-cam:before {
	content: ''
}

.bi-weather-cloud:before {
	content: ''
}

.bi-webcam1:before {
	content: ''
}

.bi-webcam2:before {
	content: ''
}

.bi-windows:before {
	content: ''
}

.bi-wine-glass:before {
	content: ''
}

.bi-worker-cap:before {
	content: ''
}

.bi-youtube:before {
	content: ''
}