:focus {
	outline: 0
}

html,
body {
	overflow-x: hidden!important;
	text-rendering: optimizeLegibility!important;
	-webkit-font-smoothing: antialiased!important
}

input[type=submit] {
	-webkit-appearance: none
}

body ul {
	padding: 0;
	margin: 0
}

body ul li {
	margin: 0;
	padding: 0;
	list-style: none;
	float: none
}

body a {
	outline: none;
	cursor: pointer;
	text-decoration: none;
	transition: all ease .3s;
	-webkit-transition: all ease .3s
}

body a:hover,
a:focus,
a:visited {
	outline: none!important;
	text-decoration: none!important
}

input[type=text]::-ms-clear {
	display: none
}

sup {
	font-size: 10px;
	vertical-align: super
}

sub {
	font-size: 10px;
	vertical-align: sub
}

nav {
	transition: background .4s ease 0s;
	-webkit-transition: background .4s ease 0s
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
	display: block
}

body {
	font-size: 14px;
	max-width: 100%;
	font-weight: 500;
	background: #fff;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	font-family: ubuntu, sans-serif;
	line-height: 22px;
	font-weight: 400
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: oswald, sans-serif
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0
}

@-webkit-viewport {
	width: device-width;
}

@-moz-viewport {
	width: device-width;
}

@-ms-viewport {
	width: device-width;
}

@-o-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #393f4c;
	text-rendering: optimizeLegibility!important;
	-webkit-font-smoothing: antialiased!important;
	margin: 0
}

body h2 {
	font-size: 35px;
	line-height: 35px;
	margin: 0 0 35px;
	text-align: center;
	font-weight: 600
}

body p {
	font-size: 14px;
	line-height: 25px;
	color: #777
}

body img {
	max-width: 100%
}

.pa-0 {
	padding: 0!important
}

.pa-5 {
	padding: 5px!important
}

.pa-10 {
	padding: 10px!important
}

.pa-15 {
	padding: 15px!important
}

.pa-20 {
	padding: 20px!important
}

.pa-25 {
	padding: 25px!important
}

.pa-30 {
	padding: 30px!important
}

.pt-0 {
	padding-top: 0!important
}

.pt-5 {
	padding-top: 5px!important
}

.pt-10 {
	padding-top: 10px!important
}

.pt-15 {
	padding-top: 15px!important
}

.pt-20 {
	padding-top: 20px!important
}

.pt-25 {
	padding-top: 25px!important
}

.pt-30 {
	padding-top: 30px!important
}

.pt-35 {
	padding-top: 35px!important
}

.pt-40 {
	padding-top: 40px!important
}

.pt-45 {
	padding-top: 45px!important
}

.pt-50 {
	padding-top: 50px!important
}

.pb-0 {
	padding-bottom: 0!important
}

.pb-5 {
	padding-bottom: 5px!important
}

.pb-10 {
	padding-bottom: 10px!important
}

.pb-15 {
	padding-bottom: 15px!important
}

.pb-20 {
	padding-bottom: 20px!important
}

.pb-25 {
	padding-bottom: 25px!important
}

.pb-30 {
	padding-bottom: 30px!important
}

.pb-35 {
	padding-bottom: 35px!important
}

.pb-40 {
	padding-bottom: 40px!important
}

.pb-45 {
	padding-bottom: 45px!important
}

.pb-50 {
	padding-bottom: 50px!important
}

.pl-0 {
	padding-left: 0!important
}

.pl-5 {
	padding-left: 5px!important
}

.pl-10 {
	padding-left: 10px!important
}

.pl-15 {
	padding-left: 15px!important
}

.pl-20 {
	padding-left: 20px!important
}

.pl-25 {
	padding-left: 25px!important
}

.pl-30 {
	padding-left: 30px!important
}

.pl-35 {
	padding-left: 35px!important
}

.pl-40 {
	padding-left: 40px!important
}

.pl-45 {
	padding-left: 45px!important
}

.pl-50 {
	padding-left: 50px!important
}

.pr-0 {
	padding-right: 0!important
}

.pr-5 {
	padding-right: 5px!important
}

.pr-10 {
	padding-right: 10px!important
}

.pr-15 {
	padding-right: 15px!important
}

.pr-20 {
	padding-right: 20px!important
}

.pr-25 {
	padding-right: 25px!important
}

.pr-30 {
	padding-right: 30px!important
}

.pr-35 {
	padding-right: 35px!important
}

.pr-40 {
	padding-right: 40px!important
}

.pr-45 {
	padding-right: 45px!important
}

.pr-50 {
	padding-right: 50px!important
}

.ma-0 {
	margin: 0!important
}

.ma-5 {
	margin: 5px!important
}

.ma-10 {
	margin: 10px!important
}

.ma-15 {
	margin: 15px!important
}

.ma-20 {
	margin: 20px!important
}

.ma-25 {
	margin: 25px!important
}

.ma-30 {
	margin: 30px!important
}

.mt-0 {
	margin-top: 0!important
}

.mt-5 {
	margin-top: 5px!important
}

.mt-10 {
	margin-top: 10px!important
}

.mt-15 {
	margin-top: 15px!important
}

.mt-20 {
	margin-top: 20px!important
}

.mt-25 {
	margin-top: 25px!important
}

.mt-30 {
	margin-top: 30px!important
}

.mt-35 {
	margin-top: 35px!important
}

.mt-40 {
	margin-top: 40px!important
}

.mt-45 {
	margin-top: 45px!important
}

.mt-50 {
	margin-top: 50px!important
}

.mt-55 {
	margin-top: 55px!important
}

.mt-60 {
	margin-top: 60px!important
}

.mt-65 {
	margin-top: 65px!important
}

.mt-70 {
	margin-top: 70px!important
}

.mt-75 {
	margin-top: 75px!important
}

.mt-80 {
	margin-top: 80px!important
}

.mt-85 {
	margin-top: 85px!important
}

.mt-90 {
	margin-top: 90px!important
}

.mt-95 {
	margin-top: 95px!important
}

.mb-0 {
	margin-bottom: 0!important
}

.mb-5 {
	margin-bottom: 5px!important
}

.mb-10 {
	margin-bottom: 10px!important
}

.mb-15 {
	margin-bottom: 15px!important
}

.mb-20 {
	margin-bottom: 20px!important
}

.mb-25 {
	margin-bottom: 25px!important
}

.mb-30 {
	margin-bottom: 30px!important
}

.mb-35 {
	margin-bottom: 35px!important
}

.mb-40 {
	margin-bottom: 40px!important
}

.mb-45 {
	margin-bottom: 45px!important
}

.mb-50 {
	margin-bottom: 50px!important
}

.mb-55 {
	margin-bottom: 55px!important
}

.mb-60 {
	margin-bottom: 60px!important
}

.mb-65 {
	margin-bottom: 65px!important
}

.mb-70 {
	margin-bottom: 70px!important
}

.mb-75 {
	margin-bottom: 75px!important
}

.mb-80 {
	margin-bottom: 80px!important
}

.mb-85 {
	margin-bottom: 85px!important
}

.mb-90 {
	margin-bottom: 90px!important
}

.mb-95 {
	margin-bottom: 95px!important
}

.ml-0 {
	margin-left: 0!important
}

.ml-5 {
	margin-left: 5px!important
}

.ml-10 {
	margin-left: 10px!important
}

.ml-15 {
	margin-left: 15px!important
}

.ml-20 {
	margin-left: 20px!important
}

.ml-25 {
	margin-left: 25px!important
}

.ml-30 {
	margin-left: 30px!important
}

.ml-35 {
	margin-left: 35px!important
}

.ml-40 {
	margin-left: 40px!important
}

.ml-45 {
	margin-left: 45px!important
}

.ml-50 {
	margin-left: 50px!important
}

.ml-55 {
	margin-left: 55px!important
}

.ml-60 {
	margin-left: 60px!important
}

.mr-0 {
	margin-right: 0!important
}

.mr-5 {
	margin-right: 5px!important
}

.mr-10 {
	margin-right: 10px!important
}

.mr-15 {
	margin-right: 15px!important
}

.mr-20 {
	margin-right: 20px!important
}

.mr-25 {
	margin-right: 25px!important
}

.mr-30 {
	margin-right: 30px!important
}

.mr-35 {
	margin-right: 35px!important
}

.mr-40 {
	margin-right: 40px!important
}

.mr-45 {
	margin-right: 45px!important
}

.mr-50 {
	margin-right: 50px!important
}

.mr-55 {
	margin-right: 55px!important
}

.mr-60 {
	margin-right: 60px!important
}

.ml-auto {
	margin-left: auto!important
}

.mr-auto {
	margin-right: auto!important
}

.m_auto {
	margin: 0 auto!important;
	float: none!important
}

.mp_none {
	margin: 0!important;
	padding: 0!important
}

.page_divider {
	margin: 25px auto;
	display: inline-block;
	width: 100%;
	height: 1px
}

#overview,
#features_tab,
#features,
#screenshot,
#team,
#blog,
#pricing,
#newsletter {
	width: 100%;
	min-height: 50px;
	padding: 95px 0 50px;
	margin: 0;
	overflow: hidden
}

div.even_div {
	background: #f8f8f8
}

.btn {
	border-radius: 7px;
	padding: 10px 36px;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 500
}

.btn_glob {
	background-color: #363636;
	color: #fff
}

.btn_glob:hover {
	background-color: #00e3ff;
	color: #fff
}

.btn_price {
	background-color: transparent;
	color: #00e3ff;
	border: 1px solid #00e3ff
}

.btn_price:hover {
	background-color: #00e3ff;
	color: #fff
}

.btn_getstart {
	background-color: #00e3ff;
	color: #fff
}

.btn_getstart:hover {
	background-color: #00e3ff;
	color: #fff
}

.transition.fade.in {
	opacity: 1
}

.transition.scale.fade {
	-webkit-transform: scale(.9);
	-moz-transform: scale(.9);
	-ms-transform: scale(.9);
	-o-transform: scale(.9);
	transform: scale(.9)
}

.transition.scaledown.fade {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1)
}

.transition.scale.fade.in,
.transition.scaledown.fade.in {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
}

.transition.left.fade {
	-webkit-transform: translateX(40px);
	-moz-transform: translateX(40px);
	-ms-transform: translateX(40px);
	-o-transform: translateX(40px);
	transform: translateX(40px)
}

.transition.right.fade {
	-webkit-transform: translateX(-40px);
	-moz-transform: translateX(-40px);
	-ms-transform: translateX(-40px);
	-o-transform: translateX(-40px);
	transform: translateX(-40px)
}

.transition.left.fade.in,
.transition.right.fade.in {
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	-ms-transform: translateX(0px);
	-o-transform: translateX(0px);
	transform: translateX(0px)
}

.transition.top.fade {
	-webkit-transform: translateY(-40px);
	-moz-transform: translateY(-40px);
	-ms-transform: translateY(-40px);
	-o-transform: translateY(-40px);
	transform: translateY(-40px)
}

.transition.bottom.fade {
	-webkit-transform: translateY(40px);
	-moz-transform: translateY(40px);
	-ms-transform: translateY(40px);
	-o-transform: translateY(40px);
	transform: translateY(40px)
}

.transition.top.fade.in,
.transition.bottom.fade.in {
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px)
}

.transition.flip.fade {
	-webkit-transform: rotateY(-90deg) scale(1.1);
	-moz-transform: rotateY(-90deg) scale(1.1);
	-ms-transform: rotateY(-90deg) scale(1.1);
	-o-transform: rotateY(-90deg) scale(1.1);
	transform: rotateY(-90deg) scale(1.1);
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%
}

.transition.flip.fade.in {
	-webkit-transform: rotateY(0deg) scale(1);
	-moz-transform: rotateY(0deg) scale(1);
	-ms-transform: rotateY(0deg) scale(1);
	-o-transform: rotateY(0deg) scale(1);
	transform: rotateY(0deg) scale(1);
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%
}

.content_wrapper {
	transform: translate3d(0px, 0, 0);
	-webkit-transform: translate3d(0px, 0, 0);
	transition: all ease .3s;
	-webkit-transition: all ease .3s
}

.owl-theme .owl-controls .owl-page span {
	background-color: #939393
}

.owl-theme .owl-controls .owl-page.active span {
	background-color: #363636
}

#header {
	display: block;
	min-height: 965px;
	margin: 0;
	padding: 40px 0;
	position: relative;
	background: url(../../images/hslider_bg.png) no-repeat center center;
	-webkit-background-size: cover;
	background-size: cover
}

#header .logo .text_logo {
	font-size: 30px;
	color: #fff;
	font-weight: 900;
	font-family: oswald, sans-serif
}

#header .main_navigation {
	float: right;
	display: inline-block;
	line-height: 20px
}

#header .main_navigation li {
	width: auto;
	float: left
}

#header .main_navigation li a {
	color: #fff;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 1px;
	padding: 0 7px;
	text-transform: uppercase;
	display: block;
	border-bottom: 2px solid transparent
}

#header .main_navigation li a:before,
#header .main_navigation li a:after {
	display: inline-block;
	opacity: 0;
	-webkit-transition: -webkit-transform .3s, opacity .2s;
	-moz-transition: -moz-transform .3s, opacity .2s;
	transition: transform .3s, opacity .2s
}

#header .main_navigation li a:before {
	margin-right: 7px;
	content: '[';
	-webkit-transform: translateX(20px);
	-moz-transform: translateX(20px);
	transform: translateX(20px)
}

#header .main_navigation li a:after {
	margin-left: 7px;
	content: ']';
	-webkit-transform: translateX(-20px);
	-moz-transform: translateX(-20px);
	transform: translateX(-20px)
}

#header .main_navigation li a:hover:before,
#header .main_navigation li a:hover:after,
#header .main_navigation li a:focus:before,
#header .main_navigation li a:focus:after {
	opacity: 1;
	-webkit-transform: translateX(0px);
	-moz-transform: translateX(0px);
	transform: translateX(0px)
}

#header .main_navigation li a:hover {
	color: #e7e7e7
}

#header .header_content {
	display: block;
	padding: 170px 0 0;
	width: 48%;
	margin: 0 auto;
	float: none;
	text-align: center
}

#header .header_content h1 {
	display: inline-block;
	font-size: 50px;
	color: #e6e6e6;
	font-weight: 700;
	line-height: 75px;
	margin: 0
}

#header .header_content h1 span {
	font-size: 65px;
	color: #fff
}

#header .header_content h4 {
	font-size: 18px;
	color: #e6e6e6;
	line-height: 26px;
	margin: 50px 0 0;
	font-family: ubuntu, sans-serif;
	font-weight: 400
}

#header .get_started_btn {
	width: 320px;
	height: 55px;
	margin: 90px auto 0;
	padding: 15px 0;
	color: #fff;
	font-size: 21px;
	font-weight: 600;
	background-color: #00e3ff;
	display: block;
	border-radius: 40px
}

#header .get_started_btn:hover {
	background-color: #17b3da
}

#header .brand_slider_wrapp {
	position: relative;
	bottom: -200px
}

#header .brand_slider {
	position: relative
}

#header .brand_slider .thumb-wrapper img {
	margin: 0 auto
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	#header {
		min-height: 979px
	}
	#header .brand_slider_wrapp {
		bottom: -217px
	}
}

.sticky {
	position: fixed;
	z-index: 99999;
	width: 100%;
	padding: 15px 0;
	top: 0;
	box-shadow: -3px 9px 14px -11px;
	background-color: rgba(28, 29, 28, .9);
	transition: background .1s ease 0s;
	-webkit-transition: background .1s ease 0s
}

.sticky .main_navigation {
	padding-top: 7px;
	padding-bottom: 7px
}

.sticky .main_navigation li a {
	padding: 6px 7px 0
}

#overview p {
	text-align: center;
	width: 50%;
	margin: 0 auto
}

#overview .overview_wrap {
	width: 100%;
	display: inline-block;
	margin: 50px 0 0
}

#overview .overview_display_block {
	width: 100%;
	height: 210px;
	padding: 0 30px;
	margin: 0;
	display: block;
	overflow: hidden
}

#overview .icon_box {
	border: 1px solid #aaa;
	padding: 20px 0;
	text-align: center;
	border-radius: 100%;
	width: 75px;
	height: 75px;
	margin: 0 auto 20px
}

#overview .icon_box i {
	color: #aaa;
	font-size: 35px
}

#overview h3 {
	font-size: 25px;
	line-height: 25px;
	margin: 0 0 25px;
	text-align: center
}

#overview .overview_display_block p {
	width: 100%
}

#overview .overview_display_block:hover .icon_box {
	border-color: #464646;
	transition: all ease .5s;
	-webkit-transition: all ease .5s
}

#overview .overview_display_block:hover .icon_box,
#overview .overview_display_block:hover .icon_box i,
#overview .overview_display_block:hover h3,
#overview .overview_display_block:hover p {
	color: #464646;
	transition: all ease .5s;
	-webkit-transition: all ease .5s
}

#features .tabpanel_list li {
	color: #777;
	padding: 5px 5px 5px 35px;
	line-height: 25px;
	margin-bottom: 25px;
}

#features .tabpanel_txtblock {
	width: 55%;
	display: block;
	float: left;
	font-weight: 600
}

#features .tabpanel_txtblock h2 {
	text-align: left
}

#features .tabpanel_txtblock p {
	text-align: left;
	margin: 0 0 35px
}

#features .tabpanel_imgblock {
	width: 40%;
	display: block;
	float: right;
	max-height: 570px
}

#features .tabpanel_imgblock img {
	max-height: 570px;
	float: right
}

#features .tab-content>.tab-pane {
	margin-bottom: 30px;
	min-height: 570px
}

#features .nav_tabs_wrap {
	padding-top: 20px
}

#features .nav-tabs {
	border: 1px solid #dcdcdc;
	border-radius: 10px
}

#features .nav-tabs>li>a {
	border: none;
	padding: 15px 67.2px 15px 68px;
	color: #777;
	font-weight: 600;
	text-transform: uppercase
}

#features .nav-tabs>li.active>a,
#features .nav-tabs>li.active>a:hover,
#features .nav-tabs>li.active>a:focus {
	border: none;
	color: #2a2a2a;
	cursor: pointer;
	background-color: transparent
}

#features .nav-tabs>li>a:hover,
#features .nav-tabs>li>a:focus {
	border: none;
	color: #2a2a2a
}

#features .nav-tabs>li.active>a:hover,
#features .nav-tabs>li.active>a:focus,
#features .nav>li>a:hover,
#features .nav>li>a:focus {
	background-color: transparent;
	border: none;
	border-radius: 0
}

#features .nav-tabs>li.active>a:before {
	content: "";
	height: 10px;
	left: 127px;
	position: absolute;
	top: -9px;
	width: 18px;
	z-index: 9
}

#features .nav-tabs>li>a:hover:before,
#features .nav-tabs>li>a:focus:before {
	content: "";
	height: 10px;
	left: 127px;
	position: absolute;
	top: -9px;
	width: 18px;
	z-index: 9
}

#main div.content {
	width: 952px;
	margin: 0 auto;
	position: relative
}

#main mark {
	background: #ff0;
	color: #000
}

#main code,
#main kbd,
#main pre,
#main samp {
	font-family: monospace;
	font-size: 1em
}

#main pre {
	white-space: pre-wrap;
	overflow: visible;
	border: 0
}

#main .hljs {
	display: block;
	overflow-x: auto;
	padding: .5em;
	color: #333;
	background: #f8f8f8;
	-webkit-text-size-adjust: none
}

#main .diff .hljs-header,
#main .hljs-comment,
#main .hljs-javadoc,
#main .hljs-template_comment {
	color: #998;
	font-style: italic
}

#main .css .rule .hljs-keyword,
#main .hljs-keyword,
#main .hljs-request,
#main .hljs-status,
#main .hljs-subst,
#main .hljs-winutils,
#main .javascript .hljs-title,
#main .nginx .hljs-title {
	color: #333;
	font-weight: 700
}

#main .hljs-hexcolor,
#main .hljs-number,
#main .ruby .hljs-constant {
	color: teal
}

#main .hljs-dartdoc,
#main .hljs-phpdoc,
#main .hljs-string,
#main .hljs-tag .hljs-value,
#main .tex .hljs-formula {
	color: #d14
}

#main .hljs-id,
#main .hljs-title,
#main .scss .hljs-preprocessor {
	color: #900;
	font-weight: 700
}

#main .hljs-list .hljs-keyword,
#main .hljs-subst,
#main .javascript .hljs-title {
	font-weight: 400
}

#main .hljs-class .hljs-title,
#main .hljs-type,
#main .tex .hljs-command,
#main .vhdl .hljs-literal {
	color: #458;
	font-weight: 700
}

#main .django .hljs-tag .hljs-keyword,
#main .hljs-rules .hljs-property,
#main .hljs-tag,
#main .hljs-tag .hljs-title {
	color: navy;
	font-weight: 400
}

#main .hljs-attribute,
#main .hljs-variable,
#main .lisp .hljs-body {
	color: teal
}

#main .hljs-regexp {
	color: #009926
}

#main .clojure .hljs-keyword,
#main .hljs-prompt,
#main .hljs-symbol,
#main .lisp .hljs-keyword,
#main .ruby .hljs-symbol .hljs-string,
#main .scheme .hljs-keyword,
#main .tex .hljs-special {
	color: #990073
}

#main .hljs-built_in {
	color: #0086b3
}

#main .hljs-cdata,
#main .hljs-doctype,
#main .hljs-pi,
#main .hljs-pragma,
#main .hljs-preprocessor,
#main .hljs-shebang {
	color: #999;
	font-weight: 700
}

#main .hljs-deletion {
	background: #fdd
}

#main .hljs-addition {
	background: #dfd
}

#main .diff .hljs-change {
	background: #0086b3
}

#main .hljs-chunk {
	color: #aaa
}

@media screen and (max-width:1012px) {
	#main div.content {
		width: 748px
	}
}

@media screen and (max-width:640px) {
	#main div.content {
		width: 100%
	}
}

@media screen and (max-width:479px) {
	#main .workflow {
		padding-left: 20px;
		padding-right: 20px
	}
}

#main .workflow header {
	border-bottom: none
}

#main .workflow header p,
#main .workflow p {
	text-align: center;
	margin-bottom: 50px;
	line-height: 20px
}

#main #flow-boxes p {
	text-align: left
}

#main .workflow a {
	color: #777;
	border-bottom: 1px dotted #777;
	padding-bottom: 1px
}

#main .workflow a:hover {
	border-bottom-color: hsla(0, 0%, 100%, .4)
}

#main .workflow code {
	font: 14px Menlo, Monaco, Consolas, Andale Mono, monospace;
	color: #fff;
	background: rgba(189, 150, 193, .2);
	padding: 3px 4px;
	border-radius: 5px;
	background-color: #c8c8c8
}

#main .workflow #flow-switcher {
	position: relative;
	z-index: 2;
	height: 128px;
	width: 720px;
	margin: 0 auto
}

@media screen and (max-width:640px) {
	#main .workflow #flow-switcher {
		width: auto;
		height: 220px;
		margin: 0 0
	}
}

@media screen and (max-width:479px) {
	#main .workflow #flow-switcher {
		width: auto;
		height: 220px;
		margin: 0 -20px
	}
}

#main .workflow #flow-switcher>li {
	float: left;
	width: 128px;
	height: 128px;
	margin-right: 28px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
	padding-top: 41px;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	background-color: #c8c8c8;
	color: #fff;
	cursor: pointer;
	-webkit-transform: scale(.8593);
	transform: scale(.8593);
	-webkit-transition: -webkit-transform .2s;
	transition: transform .2s;
	position: relative
}

@media screen and (max-width:640px) {
	#main .workflow #flow-switcher>li {
		border-radius: 0;
		width: 20%;
		height: 220px;
		-webkit-transform: none!important;
		transform: none!important;
		padding: 0;
		margin: 0!important
	}
	#main .workflow #flow-switcher>li span {
		position: absolute;
		border-radius: 0;
		width: 190px;
		height: 20px;
		line-height: 20px;
		left: 50%;
		top: 50%;
		margin: -10px 0 0 -95px;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
		text-align: left;
		font-size: 16px;
		text-shadow: -1px 0 4px rgba(0, 0, 0, .2)
	}
	#main .workflow #flow-switcher>li br,
	#main .workflow #flow-switcher>li br:after {
		content: " "
	}
}

#main .workflow #flow-switcher>li:nth-of-type(1) {
	margin-left: -30px
}

#main .workflow #flow-switcher>li:nth-of-type(5) {
	margin-right: -30px
}

#main .workflow #flow-switcher>li.done {
	background-color: #c8c8c8
}

@media screen and (max-width:640px) {
	#main .workflow #flow-switcher>li,
	#main .workflow #flow-switcher>li.done {
		background-image: linear-gradient(to left bottom, #adadad 20%, #c8c8c8 80%)
	}
	#main .workflow #flow-switcher>li.active {
		background-image: linear-gradient(to left bottom, #9A9A9A 20%, #9A9A9A 80%)
	}
}

#main .workflow #flow-switcher>li:hover {
	-webkit-transform: scale(.95);
	transform: scale(.95)
}

#main .workflow #flow-switcher>li:active {
	-webkit-transform: scale(.92);
	transform: scale(.92)
}

#main .workflow #flow-switcher>li.active {
	-webkit-transform: scale(1);
	transform: scale(1);
	background-color: #9a9a9a
}

@media screen and (max-width:640px) {
	#main .workflow #flow-switcher>li.active:after {
		content: "";
		position: absolute;
		height: 0;
		width: 0;
		left: 50%;
		margin-left: -12px;
		bottom: -24px;
		border: 12px solid transparent;
		border-top-color: #9a9a9a
	}
}

#main .workflow #flow-boxes {
	zoom: 1;
	position: relative;
	z-index: 1;
	top: -65px;
	margin-bottom: -57px;
	overflow: hidden;
	height: 315px
}

#main .workflow #flow-boxes:after,
#main .workflow #flow-boxes:before {
	content: "";
	display: table
}

#main .workflow #flow-boxes:after {
	clear: both
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes {
		top: 0;
		margin: 0 -20px;
		height: auto
	}
}

#main .workflow #flow-boxes>div {
	width: 830px;
	height: 180px;
	margin: 0 auto;
	position: absolute;
	top: 114px;
	left: 50%;
	margin-left: -415px;
	z-index: 4;
	pointer-events: none
}

#main .workflow #flow-boxes>div.active {
	z-index: 5;
	pointer-events: auto
}

@media screen and (max-width:1012px) {
	#main .workflow #flow-boxes>div {
		width: 748px;
		margin-left: -374px
	}
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes>div {
		position: static;
		width: auto;
		margin: 0;
		padding: 30px 20px 0;
		height: auto;
		display: none
	}
	#main .workflow #flow-boxes>div.active {
		display: block
	}
}

@media screen and (max-width:479px) {
	#main .workflow #flow-boxes>div {
		position: static;
		width: auto;
		margin: 0;
		padding: 30px 2px 0;
		height: auto;
		display: none
	}
}

#main .workflow #flow-boxes .codebox {
	position: absolute;
	top: 0;
	bottom: 0
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox {
		position: static
	}
}

#main .workflow #flow-boxes .codebox,
#main .workflow #flow-boxes .codebox-container,
#main .workflow #flow-boxes .text {
	opacity: 0;
	-ms-filter: "alpha(opacity=0)";
	-webkit-filter: alpha(opacity=0);
	-moz-filter: alpha(opacity=0);
	-ms-filter: alpha(opacity=0);
	-o-filter: alpha(opacity=0);
	filter: alpha(opacity=0);
	will-change: opacity;
	-webkit-transition: opacity .4s, -webkit-transform .7s;
	transition: opacity .4s, transform .7s;
	-webkit-transition-timing-function: cubic-bezier(.35, 0, .23, 1);
	transition-timing-function: cubic-bezier(.35, 0, .23, 1);
	pointer-events: none
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox,
	#main .workflow #flow-boxes .codebox-container,
	#main .workflow #flow-boxes .text {
		display: none;
		-webkit-transition: none!important;
		transition: none!important
	}
}

#main .workflow #flow-boxes .codebox-container.hiddenLeft,
#main .workflow #flow-boxes .codebox.hiddenLeft,
#main .workflow #flow-boxes .text.hiddenLeft {
	-webkit-transform: scale(.85) translateX(-200px);
	transform: scale(.85) translateX(-200px)
}

#main .workflow #flow-boxes .codebox-container.hiddenRight,
#main .workflow #flow-boxes .codebox.hiddenRight,
#main .workflow #flow-boxes .text.hiddenRight {
	-webkit-transform: scale(1.1) translate(150px);
	transform: scale(1.1) translate(150px)
}

#main .workflow #flow-boxes .codebox-container.active,
#main .workflow #flow-boxes .codebox.active,
#main .workflow #flow-boxes .text.active {
	opacity: 1;
	-webkit-filter: none;
	-moz-filter: none;
	-ms-filter: none;
	-o-filter: none;
	filter: none;
	pointer-events: auto
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox-container.active,
	#main .workflow #flow-boxes .codebox.active,
	#main .workflow #flow-boxes .text.active {
		display: block
	}
	#main .workflow #flow-boxes .codebox-container.hiddenLeft,
	#main .workflow #flow-boxes .codebox.hiddenLeft,
	#main .workflow #flow-boxes .text.hiddenLeft {
		display: none
	}
	#main .workflow #flow-boxes .codebox-container.hiddenRight,
	#main .workflow #flow-boxes .codebox.hiddenRight,
	#main .workflow #flow-boxes .text.hiddenRight {
		display: none
	}
}

#main .workflow #flow-boxes .text.active {
	z-index: 5
}

#main .workflow #flow-boxes .codebox-container {
	-webkit-transition: opacity .2s, -webkit-transform .4s;
	transition: opacity .2s, transform .4s
}

#main .workflow #flow-boxes .codebox-container.hiddenLeft {
	-webkit-transform: scale(.5);
	transform: scale(.5)
}

#main .workflow #flow-boxes .codebox-container {
	width: 440px;
	background: #f5f7f9;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
	text-shadow: none;
	overflow: hidden;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	height: 180px;
	z-index: 10
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox-container {
		width: auto;
		margin: 0;
		height: auto;
		overflow: auto
	}
	#main .workflow #flow-boxes .codebox-container:after {
		content: none
	}
}

#main .workflow #flow-boxes .codebox-container:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 47px;
	background: #ebeff3;
	border-right: 1px solid #e3e7ea;
	border-radius: 5px 0 0 5px
}

#main .workflow #flow-boxes .codebox-container:after {
	content: "";
	position: absolute;
	left: 400px;
	top: 0;
	bottom: 0;
	width: 40px;
	border-radius: 0 6px 6px 0;
	pointer-events: none;
	z-index: 11
}

#main .workflow #flow-boxes .codebox-container pre {
	white-space: pre;
	font: 12px/19px Menlo, Monaco, Consolas, Andale Mono, monospace;
	margin: 0 0 0 48px;
	padding: 0 20px;
	display: inline-block;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

#main .workflow #flow-boxes .codebox-container pre span {
	position: relative;
	left: -332px
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox-container pre {
		position: relative;
		top: 0;
		-webkit-transform: none;
		transform: none;
		padding: 20px
	}
}

#main .workflow #flow-boxes .codebox-container pre:before {
	content: "1 2 3 4 5 6 7 8";
	position: absolute;
	width: 10px;
	left: -28px;
	top: 0;
	bottom: 0;
	white-space: normal;
	overflow: hidden;
	text-align: right;
	color: #c4c8cc
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .codebox-container pre:before {
		top: 20px;
		bottom: 20px
	}
}

#main .workflow #flow-boxes .codebox-container pre .url {
	color: #4ba144
}

#main .workflow #flow-boxes .text {
	position: absolute;
	width: 324px;
	right: 0;
	top: 50%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

@media screen and (max-width:1012px) {
	#main .workflow #flow-boxes .text {
		width: 250px
	}
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .text {
		position: static;
		width: auto;
		margin: 20px 0 0!important
	}
}

#main .workflow #flow-boxes .text.three-lines {
	margin-top: -50px
}

#main .workflow #flow-boxes .text.four-lines {
	margin-top: -62px
}

#main .workflow #flow-boxes .text:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	left: -20px;
	top: 7px;
	border-radius: 50%;
	background: #c8c8c8
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .text:before {
		content: none
	}
}

#main .workflow #flow-boxes .text h3 {
	margin-bottom: 15px
}

#main .workflow #flow-boxes .text h3:before {
	content: "";
	position: absolute;
	width: 150px;
	height: 1px;
	left: -166px;
	top: 9px;
	background: #c8c8c8
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes .text h3:before {
		content: none
	}
	#main .workflow #flow-boxes .text h3 {
		margin-top: 30px
	}
}

#main .workflow #flow-boxes #automatic-renewals:after {
	content: none
}

#main .workflow #flow-boxes #automatic-renewals .text {
	width: 400px;
	right: auto;
	left: 50%;
	margin-left: -120px
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes #automatic-renewals .text {
		margin-top: 0!important;
		width: auto
	}
}

#main .workflow #flow-boxes #automatic-renewals .text:before {
	width: 130px;
	height: 130px;
	left: -160px;
	top: -17px;
	background: 0 0;
	background-size: 100% 100%
}

@media screen and (max-width:1012px) {
	#main .workflow #flow-boxes #automatic-renewals .text:before {
		top: -24px
	}
}

@media screen and (max-width:640px) {
	#main .workflow #flow-boxes #automatic-renewals .text:before {
		content: "";
		position: static;
		float: right;
		width: 100px;
		height: 100px;
		margin: -10px -10px 0 10px
	}
}

#main .workflow #flow-boxes #automatic-renewals .text h3:before {
	content: none
}

.portfolio {
	clear: both;
	float: left;
	width: 100%;
	padding: 116px 0 86px
}

.work {
	margin-bottom: 30px
}

.work img {
	width: 100%
}

.work a {
	display: block;
	overflow: hidden;
	position: relative
}

.work-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: block;
	padding: 20px
}

.work-inner:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #000;
	opacity: 0;
	-webkit-transition: all .4s;
	transition: all .4s;
	z-index: 1
}

.work:hover .work-inner:after {
	opacity: .8
}

.work-info {
	text-align: center;
	margin-top: 20%;
	top: -15px;
	position: relative
}

.work-info i {
	position: relative;
	z-index: 2;
	opacity: 0;
	-webkit-transition: all .3s;
	transition: all .3s
}

.work-info i {
	font-size: 14px;
	top: 20px;
	font-weight: 700;
	-webkit-transition: all .6s;
	transition: all .6s;
	color: #333;
	background: #fff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px
}

.work:hover .work-info p,
.work:hover .work-info h2,
.work:hover .work-info i {
	opacity: 1;
	top: 0
}

.portfolio-filter {
	list-style: outside none none;
	margin-bottom: 40px;
	padding: 0;
	text-align: center
}

.portfolio-filter li {
	display: inline-table;
	text-transform: uppercase;
	font-size: 14px;
	padding: 5px 15px;
	margin: 0 2px;
	cursor: pointer;
	position: relative;
	background: #f8f8f8;
	transition: all .3s ease 0s
}

.portfolio-filter li.active {
	color: #fff
}

.portfolio-filter li:after {
	position: absolute;
	content: "";
	width: 0;
	height: 3px;
	left: 0;
	bottom: 0;
	-webkit-transition: all .4s;
	transition: all .4s
}

.portfolio-filter li.active:after {
	width: 100%
}

.work-popup {
	max-width: 600px;
	background: #fff;
	position: relative!important;
	margin: 30px auto;
	left: 0!important
}

.work-popup img {
	width: 100%;
	height: 100%
}

.mfp-close-btn-in .mfp-close {
	position: absolute;
	background: #fff;
	font-size: 40px;
	width: 50px;
	height: 50px;
	opacity: 1;
	box-shadow: -5px 5px 20px rgba(0, 0, 0, .2)
}

.pixxett-tabs .category-product {
	margin-top: 0;
	margin-bottom: 0;
	overflow: hidden;
	position: relative;
	margin-bottom: 0
}

.pixxett-tabs .pixxett-tabs {
	position: relative;
	text-align: center;
	margin: 30px 0!important;
	padding: 0!important
}

.pixxett-tabs .category-product .nav.pixxett-tabs {
	float: right
}

.pixxett-tabs .category-product .pixxett-tabs>li:last-child {
	margin-right: 0
}

.pixxett-tabs .pixxett-tabs li.active a,
.pixxett-tabs .pixxett-tabs li.active a:hover {
	border-bottom: 2px solid #00e3ff
}

.pixxett-tabs .pixxett-tabs li {
	display: inline-block;
	position: relative
}

.pixxett-tabs .pixxett-tabs li>a {
	font-size: 16px;
	color: #343f49;
	text-transform: uppercase;
	padding: 0 7px 0;
	display: inline-block;
	line-height: 30px;
	cursor: pointer;
	font-weight: 600;
	border-bottom: 2px solid #fff
}

.pixxett-tabs .pixxett-tabs li>a:hover,
.pixxett-tabs .pixxett-tabs li>a:focus {
	border-bottom: 2px solid #00e3ff;
	text-decoration: none
}

.pixxett-tabs .product-bestseller .product-list li {
	border-right: 1px solid #eaeaea;
	padding-bottom: 10px
}

.pixxett-tabs .tab-container {
	position: relative
}

.pixxett-tabs .tab-container .tab-panel {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden
}

.pixxett-tabs .tab-container .active {
	opacity: 1;
	visibility: inherit;
	position: inherit;
	-vendor-animation-duration: .3s;
	-vendor-animation-delay: 1s;
	-vendor-animation-iteration-count: infinite
}

#testimonials .testimonials_slider_wrapp {
	width: 600px;
	margin: 70px auto 0;
	float: none
}

#testimonials .message {
	max-height: 90px;
	overflow: hidden;
	margin-bottom: 40px
}

#testimonials .message h3 {
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	margin: 0
}

#testimonials .client_image {
	border: 2px solid #d7d7d7;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	line-height: 80px;
	margin: auto;
	margin-top: 5px;
	overflow: hidden
}

#testimonials .client_image img {
	width: 100%;
	vertical-align: top
}

#testimonials .client_name {
	font-size: 20px;
	color: #737373;
	text-transform: uppercase;
	line-height: 20px;
	text-align: center;
	margin: 25px 0 0;
	font-weight: 600
}

#testimonials .client_company_info {
	font-size: 15px;
	color: #777;
	text-align: center;
	margin: 10px 0 0;
	line-height: 20px;
	font-weight: 300
}

#testimonials .owl-item {
	margin-bottom: 40px;
	min-height: 150px
}

#testimonials .item-inner {
	margin: 0 15px
}

#testimonials .owl-theme .owl-controls {
	margin: 0
}

#testimonials {
	width: 100%;
	min-height: 50px;
	padding: 20px 15px;
	margin: 0
}

#team .team_slider_wrapp {
	margin: 70px auto 0;
	float: none;
	padding: 0 15px
}

#team .thumb-wrapper {
	background-color: #fff
}

#team .text_block {
	min-height: 175px;
	overflow: hidden;
	padding: 0 25px
}

#team .tmember_image {
	margin: auto;
	margin-top: 0;
	overflow: hidden;
	border-radius: 5px
}

#team .tmember_image img {
	width: 100%;
	vertical-align: top
}

#team .tmember_name {
	font-size: 23px;
	color: #5c5c5c;
	text-transform: capitalize;
	line-height: 20px;
	text-align: center;
	margin: 35px 0 0;
	font-weight: 600
}

#team .tmember_info {
	font-size: 15px;
	color: #777;
	text-align: center;
	margin: 20px 0 0;
	line-height: 20px;
	font-weight: 300;
	letter-spacing: 1.2px
}

#team .text_block p {
	font-size: 15px;
	line-height: 20px;
	color: #676767;
	text-align: center;
	margin: 41px 0 42px;
	border-radius: 5px
}

#team .thumb-wrapper,
#team .tmember_image img,
#team .tmember_name,
#team .tmember_info,
#team .text_block p {
	transition: all ease .5s;
	-webkit-transition: all ease .5s
}

#team .owl-item {
	margin-bottom: 40px;
	min-height: 150px
}

#team .owl-theme .owl-controls {
	margin: 0
}

#team .thumb-wrapper:hover {
	background-color: #f1f1f1
}

#team .thumb-wrapper:hover .tmember_name {
	color: #737373
}

#team .thumb-wrapper:hover .tmember_info,
#team .thumb-wrapper:hover .text_block p {
	color: #777
}

#team .thumb-wrapper:hover .tmember_image img {
	opacity: .8
}

.pricing h2 {
	color: #393f4c
}

.pricing h3 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	letter-spacing: normal;
	margin-bottom: 15px;
	text-align: center;
	font-family: ubuntu, sans-serif;
	width: 75%;
	margin: 0 auto 25px;
	color: #333
}

.pricing-box .pricing_wrap {
	display: block;
	padding: 0 10px 5px
}

.package_wrapp .pricing-box {
	border: 1px solid #eee;
	border-bottom: 2px solid;
	width: 100%
}

.package_wrapp .pricing-details {
	color: #393f4c
}

.pricing-details .price_show {
	margin: 30px 0 0
}

.package_wrapp .pricing-details ul {
	display: inline-block;
	padding: 5px 0 20px;
	margin: 30px 0 0 7px;
	min-height: 390px;
	max-width: 225px
}

.package_wrapp .features .plus {
	font-size: 22px
}

.package_wrapp .features strong {
	font-weight: 700
}

.package_wrapp .features.none {
	padding: 30px 0 0;
	margin: 55px 0 0 7px;
	min-height: 400px
}

.package_wrapp .feature-list {
	text-align: left;
	display: flex;
	padding: 5px 0;
	font-size: 15px
}

.package_wrapp .feature-list i {
	padding: 3px 7px 0 0
}

.package_wrapp .feature-list strong {
	padding: 10px 0;
	text-align: left;
	font-weight: 700
}

.pricing .trusted-by-container {
	width: 100%
}

.pricing-flex-top {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap
}

.pricing-flex {
	display: flex
}

.pricing-element {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 765px
}

.package_wrapp .pricing-box.free-pricing {
	border-color: #e5e5e6;
	margin-right: 10px
}

.package_wrapp .pricing-box.free-pricing .header {
	background-color: #7e7e7e;
	color: #fff
}

.package_wrapp .pricing-box.free-pricing .price {
	color: #4d9de0;
	font-size: 70px
}

.pricing-box.free-pricing .button-wrapper {
	padding: 0 20px;
	margin-bottom: 15px
}

.pricing-box.paid-pricing {
	background-color: #fff;
	border-color: #cae3ff;
	margin: 0 10px 0 0;
	padding: 0;
	position: relative;
	border-radius: 5px;
	overflow: hidden
}

.pricing-box.business-plan {
	background-color: #fffdfb;
	border-color: #ffdac1;
	margin: 0 10px 0 0;
	position: relative;
	border-radius: 5px;
	overflow: hidden
}

.pricing-box .header {
	font-size: 20px;
	padding: 20px;
	text-align: center;
	margin: 0 auto 10px;
	width: 100%
}

.pricing-box .header span {
	font-weight: 700
}

.pricing-box.paid-pricing .header {
	background-color: #26c6da;
	color: #fff;
	margin: -1px -1px 0;
	width: 100.7%
}

.pricing-box.business-plan .header {
	background-color: #fa7921;
	color: #fff;
	margin: -1px -1px 0;
	width: 100.7%
}

.pricing-box .pricing_wrap .subscribers {
	font-weight: 600;
	position: relative;
	top: 23px;
	left: 23px;
	font-size: 13px;
	margin: 0 20px 10px 0;
	padding: 5px 0 0
}

.pricing-box .pricing_wrap .pricing_slider_wrap {
	margin: 0 30px 0 0;
	padding: 0 50px;
	display: block
}

.pricing-box.paid-pricing .button-wrapper,
.pricing-box.business-plan .button-wrapper {
	padding: 10px 20px 15px;
	width: 97%
}

.pricing-box .btn {
	border-radius: 2px;
	display: block;
	width: 100%;
	padding: 15px 12px;
	font-size: 17px;
	text-transform: uppercase;
	margin: 10px 5px;
	box-shadow: 0 -2px 0 0 rgba(1, 1, 1, .1) inset;
	transition: all .5s ease 0s;
	color: #fff;
	letter-spacing: 1.5px
}

.pricing-box .btn:hover {
	color: #fff
}

.pricing-box .btn-grey {
	background-color: #26c6da;
	border-color: #26c6da
}

.pricing-box .btn-grey:hover {
	background-color: #19477b;
	border-color: #19477b
}

.pricing-box .btn-success {
	background-color: #fa7921;
	border-color: #fa7921;
	margin-top: 15px
}

.pricing-box .btn-success:hover {
	background-color: #df6c1d;
	border-color: #df6c1d
}

.pricing-box .button-wrapper span {
	color: #393f4c;
	text-align: center;
	font-size: 15px;
	display: block
}

.get_coat {
	margin-bottom: 7.5px
}

.get_coat .price {
	margin: 40px auto 0!important;
	font-size: 20px!important
}

.get_coat .price_quote {
	font-size: 14px
}

.pricing .trusted-by-passive.more-link .inner {
	line-height: 4em
}

.pricing-box.paid-pricing .pricing-premium .pricing-months,
.pricing-box.business-plan .pricing-months {
	font-size: 11px;
	margin: 15px 0 10px;
	color: #a3a3a3;
	line-height: .8em
}

.pricing-premium .price {
	margin-bottom: 0;
	cursor: pointer;
	height: 34px;
	font-size: 40px;
	font-weight: 700
}

.pricing-premium .price i {
	font-size: 24px
}

.pricing-premium .price-quote {
	margin-bottom: 0
}

.pricing-faq {
	margin: 0 135px
}

.pricing-faq ul {
	padding-left: 20px
}

.pricing-faq ul li:before {
	content: "\f14a";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	margin: 0 5px 0 -15px;
	padding-right: 11px;
	color: #777
}

.pricing-faq ul hr {
	width: 99%;
	margin-left: 15px
}

.features.fix-width li {
	width: 270px
}

.pricing-faq .faq-answer {
	padding-left: 17px
}

.adding-pushcrew-link {
	color: #777!important;
	font-size: .4em;
	padding-right: 10px;
	vertical-align: 3px
}

.adding-pushcrew-link-div {
	margin-left: 45px
}

#blog .blog_wrapp {
	margin: 75px auto 0;
	float: none
}

#blog .blog_block {
	background: #fff;
	float: left;
	-webkit-transition: all ease-in .25s;
	transition: all ease-in .25s
}

#blog .blog_block:hover {
	-webkit-transition: all .2s linear;
	overflow: hidden;
	-webkit-transform: translate3d(0, -2px, 0);
	transform: translate3d(0, -2px, 0);
	-webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, .18);
	box-shadow: 0 8px 16px rgba(0, 0, 0, .18);
	transition: -webkit-box-shadow .2s linear;
	transition: box-shadow .2s linear;
	transition: box-shadow .2s linear, -webkit-box-shadow .2s linear
}

.blog_img {
	position: relative;
	overflow: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden
}

.blog_img,
.blog_img a {
	display: block;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-o-perspective: 1000;
	perspective: 1000
}

.blog_img img {
	width: 100%;
	height: auto;
	transition: all .4s;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden
}

.blog_block:hover img {
	filter: brightness(80%);
	-moz-filter: brightness(80%);
	-ms-filter: brightness(80%);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden
}

.blog_img_hoverlay {
	background: rgba(0, 0, 0, .8);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: all .4s;
	-webkit-transform-origin: bottom;
	transform-origin: bottom;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-transform: rotateX(90deg);
	-moz-transform: rotateX(90deg);
	-o-transform: rotateX(90deg);
	transform: rotateX(90deg)
}

.blog_img_hoverlay_icon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	-webkit-transform: translateY(-60px) scale(1.5);
	transform: translateY(-60px) scale(1.5);
	transition: all .45s
}

.blog_img_hoverlay_icon .fa {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -13px;
	margin-left: -13px;
	font-size: 26px;
	color: #fff;
	cursor: pointer
}

.blog_block:hover .blog_img_hoverlay {
	opacity: .5;
	-webkit-transform: rotateX(0);
	-moz-transform: rotateX(0);
	-o-transform: rotateX(0);
	transform: rotateX(0)
}

.blog_block:hover .blog_img_hoverlay_icon {
	opacity: 1;
	-webkit-transform: translateY(0) scale(1);
	transform: translateY(0) scale(1)
}

.blog_img_overflow {
	position: absolute;
	top: 0;
	left: -68px;
	width: 68px
}

#blog .blog_info {
	width: 100%;
	margin: 0;
	padding: 20px;
	font-weight: 600;
	letter-spacing: .5px
}

#blog .blog_info h3 {
	margin-top: 0;
	margin-bottom: 10px
}

#blog .blog_info h3 a {
	color: #333;
	margin: 0;
	font-size: 18px;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inherit;
	letter-spacing: .8px
}

#blog .blog_info p {
	line-height: 20px;
	color: #777;
	text-align: left;
	font-weight: 300;
	letter-spacing: normal
}

#blog .blog_info span {
	font-size: 13px;
	line-height: 20px;
	margin-bottom: 15px;
	color: #999;
	text-align: left;
	display: inline-block
}

#blog .blog_info span span {
	color: #999;
	font-style: italic;
	margin: 0
}

#blog .blog_info span.year {
	font-size: 13px;
	line-height: 20px;
	color: #999;
	font-family: roboto, sans-serif;
	font-weight: 500;
	margin: 0;
	padding-top: 6px
}

#blog .blog_info .btn_glob {
	background-color: transparent;
	border-color: #fff;
	color: #999;
	float: left;
	margin-bottom: 20px;
	padding: 0;
	font-size: 13px;
	text-transform: capitalize;
	margin-top: 15px
}

#blog .blog_info .btn_glob:hover {
	text-decoration: underline!important;
	box-shadow: none
}

#blog .blog_img span {
	font-size: 12px;
	line-height: 20px;
	padding: 7px 10px;
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .6);
	color: #ddd;
	display: block;
	letter-spacing: .5px
}

#blog .blog_img span i {
	display: inline;
	margin-right: 7px
}

#screenshot .screenshot_slider_wrapp {
	margin: 75px auto 0;
	float: none
}

#screenshot .item-inner {
	margin: 0 15px
}

#screenshot .owl-item {
	margin-bottom: 40px
}

#newsletter {
	display: inline-block;
	min-height: 385px;
	margin: 0;
	padding: 100px 0 42px;
	position: relative;
	-webkit-background-size: cover;
	background-size: cover;
	background: #333
}

#newsletter h2 {
	font-size: 36px;
	line-height: 1.2;
	color: #fff;
	text-align: center;
	margin-top: 0;
	font-weight: 900;
	position: relative;
	margin-bottom: 10px
}

.title-underline {
	display: block;
	width: 10px;
	height: 10px;
	margin: auto;
	position: relative
}

.contact .title-underline:before {
	content: "";
	display: block;
	position: absolute;
	width: 39px;
	height: 1px;
	border-radius: 50%;
	background: #fff;
	background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -ms-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -o-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	top: 14px;
	left: -47px
}

.contact .title-underline i {
	color: #fff
}

.contact .title-underline:after {
	content: "";
	display: block;
	position: absolute;
	width: 39px;
	height: 1px;
	border-radius: 50%;
	background: #fff;
	background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -ms-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: -o-linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 8%, #fff 92%, rgba(255, 255, 255, 0) 100%);
	top: 14px;
	right: -49px
}

#newsletter p {
	font-size: 22px;
	line-height: 25px;
	color: #fff;
	text-align: center;
	margin-bottom: 80px;
	font-weight: 500
}

#newsletter.contact .section-title h2 {
	width: 550px;
	margin: auto;
	margin-top: 15px;
	color: #fff
}

#newsletter.contact .section-title p {
	width: 550px;
	margin: auto;
	margin-top: 15px;
	line-height: 26px;
	font-size: 16px;
	color: #fff;
	margin-bottom: 40px;
	font-weight: 300
}

.contact .contact-form form input {
	width: 100%;
	margin-bottom: 10px;
	margin-top: 10px;
	height: 50px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	outline: 0;
	color: #ddd;
	background: #222;
	border: 1px solid #161616
}

.contact .contact-form form textarea {
	width: 100%;
	margin-bottom: 10px;
	margin-top: 10px;
	height: 200px;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	outline: 0;
	color: #ddd;
	resize: none;
	background: #222;
	border: 1px solid #161616
}

.contact .contact-form form .send-butt {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px
}

.contact .contact-form form .send-butt a {
	font-size: 14px;
	background: #00e3ff;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 4px;
	color: #fff;
	min-width: 150px;
	border: 0;
	padding: 16px 25px;
	margin: 5px;
	letter-spacing: 1.2px
}
.contact .contact-form form .send-butt button {
	font-size: 14px;
	background: #00e3ff;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 4px;
	color: #fff;
	min-width: 150px;
	border: 0;
	padding: 16px 25px;
	margin: 5px;
	letter-spacing: 1.2px
}

::-webkit-input-placeholder {
	color: #777
}

:-moz-placeholder {
	color: #777
}

::-moz-placeholder {
	color: #777
}

:-ms-input-placeholder {
	color: #777
}

#footer {
	display: block;
	background-color: #111;
	min-height: 50px;
	margin: 0;
	padding: 40px 0 30px;
	position: relative;
	line-height: 35px
}

#footer .coppyright {
	color: #777;
	font-size: 15px
}

#footer .coppyright a {
	color: #777
}

#footer .social_links {
	display: inline-block;
	float: right
}

#footer .social_links li {
	padding: 0 7px;
	float: left
}

#footer .social_links li i {
	color: #fff;
	font-size: 23px
}

#footer .social_links li i:hover {
	color: #d4d4d4
}

#toTop {
	color: #fff;
	text-align: center;
	bottom: 60px;
	display: none;
	height: 45px;
	line-height: 45px;
	overflow: hidden;
	position: fixed;
	right: 10px;
	text-decoration: none;
	width: 50px;
	background-color: #999;
	text-transform: uppercase;
	z-index: 100;
	border-radius: 3px
}

#toTop:hover {
	color: #fff;
	background-color: #373737
}

#sp-account {
	height: 50px;
	z-index: 999999
}

#offcanvas_toggler {
	color: #fff;
	font-size: 25px;
	padding-top: 0;
	font-weight: 600;
	height: inherit;
	text-transform: uppercase;
	float: right;
	border: 0;
	border-radius: 0;
	transition: color 400ms ease 0s, background-color 400ms ease 0s, border-color 400ms ease 0s
}

.offcanvas_overlay {
	opacity: 0;
	-webkit-transition: opacity .5s, width .1s .5s, height .1s .5s;
	transition: opacity .5s, width .1s .5s, height .1s .5s
}

body.offcanvas .offcanvas_overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, .8);
	opacity: 1;
	z-index: 9999
}

.close_offcanvas {
	position: absolute;
	top: 25px;
	right: 15px;
	z-index: 1;
	color: #fff;
	border-radius: 100%;
	width: 22px;
	height: 22px;
	line-height: 13px;
	text-align: center;
	font-size: 12px;
	background: rgba(0, 0, 0, .3);
	padding: 5px 3px 5px 2px
}

.close_offcanvas:hover {
	background: rgba(0, 0, 0, .5);
	color: #fff
}

.offcanvas_menu {
	width: 280px;
	height: 100%;
	position: fixed;
	right: -280px;
	top: 0;
	opacity: 0;
	background-color: #464646;
	z-index: 99999
}

.offcanvas_menu .offcanvas_inner {
	padding: 20px
}

.offcanvas_menu .offcanvas_inner .sp_module .sp_module_title {
	font-size: 22px;
	font-weight: 800;
	line-height: 1;
	padding: 0 20px 25px;
	margin: 0 -20px;
	color: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, .2);
	width: 117%
}

.offcanvas_menu .offcanvas_inner .sp_module ul>li {
	border: 0;
	position: relative
}

.offcanvas_menu .offcanvas_inner .sp_module ul>li a {
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, .2);
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
	padding: 15px 20px;
	font-size: 13px;
	line-height: 1;
	text-transform: uppercase
}

.offcanvas_menu .offcanvas_inner .sp_module ul>li a:hover {
	color: #111
}

.offcanvas_menu {
	-webkit-transition: opacity 400ms cubic-bezier(.7, 0, .3, 1), right 400ms cubic-bezier(.7, 0, .3, 1);
	transition: opacity 400ms cubic-bezier(.7, 0, .3, 1), right 400ms cubic-bezier(.7, 0, .3, 1)
}

.offcanvas {
	width: 100%;
	height: 100%;
	position: relative
}

.offcanvas .offcanvas_menu {
	opacity: 1
}

.bodycanvas {
	right: 0
}

.sp_module_content .menu {
	margin: 0 -20px;
	width: 117%
}

.services {
	padding: 116px 0 71px
}

h2.section-heading {
	font-size: 45px;
	margin: 10px 0 50px;
	font-weight: 600
}

.icon-box {
	margin: 0 0 40px;
	-webkit-box-shadow: 2px 0 2px -2px rgba(0, 0, 0, .27);
	-moz-box-shadow: 2px 0 2px -2px rgba(0, 0, 0, .27);
	box-shadow: 2px 0 2px -2px rgba(0, 0, 0, .27)
}

.icon-box-wrapper:nth-child(3) .icon-box,
.icon-box-wrapper:nth-child(6) .icon-box {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

.services .icon-box .icon-left {
	height: auto;
	margin-right: 20px;
	position: relative;
	text-align: center;
	top: 5px;
	transition: all .8s ease-in-out 0s;
	width: 100%;
	z-index: 1;
	margin-bottom: 25px;
	-moz-transform: scale(.8) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-webkit-transform: scale(.8) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-o-transform: scale(.8) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-ms-transform: scale(.8) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	transform: scale(.8) rotate(0deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg)
}

.icon-box:hover .icon-left {
	-moz-transform: scale(1.) rotate(360deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-webkit-transform: scale(1.2) rotate(360deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-o-transform: scale(1.2) rotate(360deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	-ms-transform: scale(1.2) rotate(360deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg);
	transform: scale(1.2) rotate(360deg) translateX(0px) translateY(0px) skewX(0deg) skewY(0deg)
}

.icon-box .icon-left span {
	color: #1d1d1d;
	font-size: 50px;
	text-shadow: 0 6px 12px rgba(0, 0, 0, .176);
	transition: all .3s ease-in-out 0s
}

.icon-box:hover .icon-left span {
	color: #00e3ff
}

.services .icon-box .icon-content {
	padding: 0 20px;
	text-align: center
}

.services .icon-box .icon-content .title {
	margin-bottom: 15px;
	font-size: 18px
}

.counters:before {
	background: #000;
	bottom: 0;
	content: "";
	left: 0;
	opacity: .8;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1
}

.counters {
	background: url(../../images/counters-bg.jpg) no-repeat fixed left top;
	margin: 0;
	padding: 91px 0;
	position: relative;
	width: 100%;
	float: left;
	text-align: center;
	z-index: 9
}

.counters .counter i {
	margin: 0 0 20px;
	font-size: 40px;
	transition: all 400ms linear 0s;
	display: block
}

.counters .counter i {
	color: #00e3ff
}

.counters .counter:hover i {
	transform: translateY(-15px)
}

.counters .counter .number {
	font-size: 30px;
	margin: 0 0 20px;
	padding: 0;
	color: #fff;
	font-weight: 600
}

.counters .counter {
	border-radius: 4px;
	min-height: 170px;
	padding: 25px 30px;
	transition: all 400ms linear 0s
}

.counters .counter p {
	font-size: 15px;
	margin: 0;
	padding: 0;
	color: #fff;
	font-weight: 500
}

.testimonials-wrapper:before {
	background: #000;
	bottom: 0;
	content: "";
	left: 0;
	opacity: .8;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1
}

.testimonials-wrapper .section-number:before,
.testimonials-wrapper .section-number:after {
	background: #fff
}

.testimonials-wrapper .section-number {
	background: #fff;
	border-color: #fff
}

.testimonials-wrapper {
	background: url(../../images/counters-bg.jpg) no-repeat fixed left top;
	margin: 0;
	position: relative;
	width: 100%;
	float: left;
	z-index: 9;
	padding: 80px 0 50px
}

.testimonials-wrapper h2 {
	color: #fff
}

.testimonial::after {
	bottom: 18px;
	color: #fff;
	content: "";
	font-family: fontawesome;
	font-size: 45px;
	opacity: .2;
	position: absolute;
	right: 10px
}

.testimonial {
	border-radius: 15px;
	overflow: visible;
	position: relative;
	padding: 30px 42px 30px 30px;
	margin: 80px 0 0;
	text-align: left
}

.testimonial {
	background: #26c6da
}

.testimonial .author {
	color: #fff;
	margin: 0 0 5px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400
}

.testimonial .title {
	color: #fff;
	margin: 0 0 15px;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 1px
}

.testimonial p {
	color: #fff;
	margin: 0
}

.testimonial .content {
	border: 5px solid #fff;
	border-radius: 50%;
	position: absolute;
	right: 40px;
	top: -35px;
	width: 100px
}

.testimonial .thumb img {
	display: block;
	transform-style: preserve-3d;
	width: auto;
	border-radius: 50%
}

.testimonials-wrapper .owl-theme .owl-nav {
	display: none
}

.testimonials-wrapper .owl-theme .owl-dots .owl-dot span {
	background: #8c8d8e;
	opacity: 1;
	margin: 0 3px;
	width: 10px;
	height: 10px
}

.testimonials-wrapper .owl-dots {
	padding: 30px 0 0
}

@media only screen and (min-width:320px) and (max-width:479px) {
	#header {
		min-height: inherit;
		padding: 20px 0
	}
	#header .main_navigation {
		display: none!important
	}
	#header .logo .text_logo {
		font-size: 22px
	}
	#header .header_content {
		padding: 50px 0 0;
		width: 100%
	}
	#header .header_content h1 {
		font-size: 30px;
		line-height: 46px
	}
	#header .header_content h1 span {
		font-size: 40px;
		color: #fff
	}
	#header .header_content h4 {
		font-size: 14px;
		line-height: 22px;
		width: 100%;
		margin: 15px auto
	}
	#header .get_started_btn {
		width: auto;
		height: auto;
		margin: 20px auto 0;
		padding: 13px 25px;
		font-size: 18px;
		display: inline-block
	}
	#header .brand_slider_wrapp {
		bottom: -30px
	}
	#header .brand_slider .thumb-wrapper img {
		width: 41%
	}
	.services,
	.portfolio,
	.testimonials-wrapper,
	#team,
	#blog,
	#pricing,
	#newsletter {
		padding: 30px 0
	}
	h2.section-heading {
		font-size: 30px;
		margin: 10px 0 20px
	}
	.services .icon-box-wrapper .icon-box {
		padding: 15px;
		margin: 0 0 15px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border: 1px solid #e5e5e5
	}
	#team .text_block p {
		font-size: 14px;
		margin: 15px 0 20px
	}
	#team .team_slider_wrapp {
		margin: 35px auto 0
	}
	body h2 {
		font-size: 30px;
		margin: 10px 0 20px
	}
	#blog .blog_wrapp,
	#pricing .package_wrapp {
		margin: 30px -15px 0
	}
	#blog .blog_block {
		margin-bottom: 25px
	}
	.pricing h3 {
		font-size: 14px
	}
	.pricing-box.paid-pricing,
	.pricing-box.business-plan {
		margin: 0 0 15px;
		width: 100%
	}
	#newsletter.contact .section-title h2,
	#newsletter.contact .section-title p {
		width: inherit
	}
	#newsletter h2 {
		font-size: 30px
	}
	section h2,
	div h2 {
		margin: 40px 0 15px!important
	}
}

@media only screen and (min-width:480px) and (max-width:767px) {
	#header {
		min-height: inherit;
		padding: 20px 0
	}
	#header .main_navigation {
		display: none!important
	}
	#header .logo .text_logo {
		font-size: 22px
	}
	#header .header_content {
		padding: 50px 0 0;
		width: 100%
	}
	#header .header_content h1 {
		font-size: 34px;
		line-height: 46px
	}
	#header .header_content h1 span {
		font-size: 44px;
		color: #fff
	}
	#header .header_content h4 {
		font-size: 14px;
		line-height: 22px;
		width: 100%;
		margin: 15px auto
	}
	#header .get_started_btn {
		width: auto;
		height: auto;
		margin: 20px auto 0;
		padding: 13px 25px;
		font-size: 18px;
		display: inline-block
	}
	#header .brand_slider_wrapp {
		bottom: -30px
	}
	#header .brand_slider .thumb-wrapper img {
		width: 50%
	}
	.services,
	.portfolio,
	.testimonials-wrapper,
	#team,
	#blog,
	#pricing,
	#newsletter {
		padding: 30px 0
	}
	h2.section-heading {
		font-size: 34px;
		margin: 10px 0 20px
	}
	.services .icon-box-wrapper .icon-box {
		padding: 15px;
		margin: 0 0 15px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border: 1px solid #e5e5e5
	}
	#team .text_block p {
		font-size: 14px;
		margin: 15px 0 20px
	}
	body h2 {
		font-size: 34px;
		margin: 10px 0 20px
	}
	#blog .blog_wrapp,
	#pricing .package_wrapp {
		margin: 30px -15px 0
	}
	#blog .blog_block {
		margin-bottom: 25px
	}
	.pricing h3 {
		font-size: 14px
	}
	.pricing-box.paid-pricing,
	.pricing-box.business-plan {
		margin: 0 0 15px;
		width: 100%
	}
	#newsletter.contact .section-title h2,
	#newsletter.contact .section-title p {
		width: inherit
	}
	#newsletter h2 {
		font-size: 34px
	}
	section h2,
	div h2 {
		margin: 35px 0 20px!important
	}
	#footer .social_links li i {
		font-size: 20px
	}
}

@media only screen and (min-width:768px) and (max-width:991px) {
	#header {
		min-height: inherit;
		padding: 20px 0
	}
	#header .main_navigation {
		display: none!important
	}
	#header .logo .text_logo {
		font-size: 22px
	}
	#header .header_content {
		padding: 50px 0 0;
		width: 100%
	}
	#header .header_content h1 {
		font-size: 34px;
		line-height: 46px
	}
	#header .header_content h1 span {
		font-size: 44px;
		color: #fff
	}
	#header .header_content h4 {
		font-size: 14px;
		line-height: 22px;
		width: 100%;
		margin: 15px auto
	}
	#header .get_started_btn {
		width: auto;
		height: auto;
		margin: 20px auto 0;
		padding: 13px 25px;
		font-size: 18px;
		display: inline-block
	}
	#header .brand_slider_wrapp {
		bottom: -30px
	}
	#header .brand_slider .thumb-wrapper img {
		width: 50%
	}
	.services,
	.portfolio,
	.testimonials-wrapper,
	#team,
	#blog,
	#pricing,
	#newsletter {
		padding: 55px 0 30px
	}
	h2.section-heading {
		font-size: 34px;
		margin: 10px 0 20px
	}
	.services .icon-box-wrapper .icon-box {
		padding: 15px;
		margin: 0 0 15px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border: 1px solid #e5e5e5
	}
	#team .text_block p {
		font-size: 14px;
		margin: 15px 0 20px
	}
	body h2 {
		font-size: 34px;
		margin: 10px 0 20px
	}
	#blog .blog_wrapp,
	#pricing .package_wrapp {
		margin: 30px -15px 0
	}
	#blog .blog_block {
		width: 100%
	}
	.pricing h3 {
		font-size: 14px
	}
	.pricing-box.paid-pricing,
	.pricing-box.business-plan {
		margin: 0 0 15px;
		width: 100%
	}
	#newsletter.contact .section-title h2,
	#newsletter.contact .section-title p {
		width: inherit
	}
	#newsletter h2 {
		font-size: 34px
	}
	.pricing-box .btn {
		font-size: 14px;
		padding: 10px 12px
	}
	.pricing-box .pricing_wrap .subscribers {
		top: 40px
	}
	.pricing-box .pricing_wrap .pricing_slider_wrap {
		margin: 0 -15px 0 0;
		padding: 0 35px
	}
	.noUi-pips {
		font: 400 11px Arial
	}
	#team .text_block {
		padding: 15px 25px
	}
}

@media only screen and (min-width:992px) and (max-width:1199px) {
	#header .main_navigation li a {
		padding: 0
	}
	.pricing-box .pricing_wrap .pricing_slider_wrap {
		padding: 0 30px
	}
	#team .tmember_image {
		padding: 0 1px
	}
}