.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.4) !important;
}
.ReactModal__Content .content {
    position: relative;
}
.ReactModal__Content  .content i {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: white;
}